import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetCategoriesByUsertypeInput,
  GetCategoriesByUsertypeResponse,
  CreateCategoryInput,
  CreateCategoryResponse,
  UpdateCategoryInput,
  UpdateCategoryResponse,
  RemoveCategoryInput,
  RemoveCategoryResponse,
} from 'src/types';

/**
 * Create Categories
 */
export const CREATE_CATEGORY_MUTATION = gql`
  mutation CREATE_CATEGORY($input: CreateCategoryInput) {
    createCategory(input: $input) {
      error
      message
      category {
        id
        usertype
        value
      }
    }
  }
`;

export function useCreateCategory(input: CreateCategoryInput) {
  return useMutation<{ createCategory: CreateCategoryResponse }, { input: CreateCategoryInput }>(
    CREATE_CATEGORY_MUTATION,
    {
      variables: { input },
      refetchQueries: [GET_CATEGORIES_BY_USERTYPE]
    },
  );
}
/**
 * Read Categories
 */

export const GET_CATEGORIES_BY_USERTYPE = gql`
  query GET_ALL_CATEGORIES_BY_USERTYPE($input: GetCategoriesByUsertypeInput) {
    getCategoriesByUsertype(input: $input) {
      categories {
        id
        value
        usertype
        main
      }
      error
      message
      usertype
    }
  }
`;

export function useGetCategoriesByUsertype(input: GetCategoriesByUsertypeInput) {
  return useQuery<
    { getCategoriesByUsertype: GetCategoriesByUsertypeResponse },
    { input: GetCategoriesByUsertypeInput }
  >(GET_CATEGORIES_BY_USERTYPE, {
    variables: { input },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
}

/**
 * Update Categories
 */
export const UPDATE_CATEGORIES_MUTATION = gql`
  mutation UPDATE_CATEGORIES($input: UpdateCategoryInput) {
    updateCategory(input: $input) {
      error
      message
      category {
        id
        value
        main
        usertype
      }
    }
  }
`;
export function useUpdateCategory(input: UpdateCategoryInput) {
  return useMutation<{ updateCategory: UpdateCategoryResponse }, { input: UpdateCategoryInput }>(
    UPDATE_CATEGORIES_MUTATION,
    { variables: { input }, refetchQueries: [GET_CATEGORIES_BY_USERTYPE] },
  );
}
/**
 * Delete Categories
 */
export const REMOVE_CATEGORIES_MUTATION = gql`
  mutation DELETE_CATEGORIES($input: RemoveCategoryInput) {
    removeCategory(input: $input) {
      error
      message
      category {
        id
        main
        usertype
        value
      }
    }
  }
`;

export function useRemoveCategory(input: RemoveCategoryInput) {
  return useMutation<{ removeCategory: RemoveCategoryResponse }, { input: RemoveCategoryInput }>(
    REMOVE_CATEGORIES_MUTATION,
    {
      variables: { input },
      refetchQueries: [GET_CATEGORIES_BY_USERTYPE]
    },
  );
}
