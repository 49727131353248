import { Typography, Button } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState, FC } from 'react';
import { useGetUsers } from 'src/graphql/userQueries/useGetUser';
import { useUserTabContext } from '../state';

interface UserTableProps { }

const columnsDef = [
	{ field: 'firstname', headerName: 'Firstname', width: 160 },
	{ field: 'lastname', headerName: 'Lastname', width: 160 },
	{ field: 'username', headerName: 'Username', width: 160 },
	{ field: 'email', headerName: 'Email', width: 200 },
	{ field: 'usertype', headerName: 'Usertype', width: 145 },
	{
		field: 'isVerified', headerName: '', width: 145, renderCell: (params: GridRenderCellParams) => {
			const { id, isVerified } = params.row;
			console.log(params.row)
			return <Typography>{isVerified ? 'True' : 'False'}</Typography>
		}
	}, {
		field: "main", headerName: ' ', width: 200, renderCell: (params: GridRenderCellParams) => {
			const { id } = params.row;
			const { verifyUser, removeUser } = useUserTabContext();
			return <div style={{ display: 'flex' }}>
				<Button
					variant="contained"
					onClick={() => { verifyUser({ userId: id }) }}
				>
					<Typography sx={{ color: '#fff', fontSize: 12 }}>Verify</Typography>
				</Button>
				<Button
					color='error'
					variant="contained"
					onClick={() => { removeUser({ userId: id }) }}
					sx={{ marginLeft: '5px' }}
				>
					<Typography sx={{ color: '#fff', fontSize: 12 }}>Delete</Typography>
				</Button>
			</div>
		}
	}
]

const UserTable: FC<UserTableProps> = () => {
	const { data, error, loading, refetch, networkStatus } = useGetUsers();
	useEffect(() => { }, [data]);
	useEffect(() => { }, [loading])
	if (data?.getAllUser) {

		return (<div style={style.container}>
			<DataGrid disableSelectionOnClick columns={columnsDef} rows={data.getAllUser} />
		</div>)
	}
	return <></>
}

const style = {
	container: {
		height: 400,
		width: '80%',
		paddingLeft: '10%',
		paddingRight: '10%',
	},
};

export default UserTable;