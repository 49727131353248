/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Box,
  InputLabel,
  Modal,
  Typography,
  FormControl,
  FormHelperText,
  Input,
  FormControlLabel,
  Checkbox,
  Select,
  Button,
} from '@mui/material';
import React, { useEffect, useState, FC, createRef } from 'react';
import BaseModal from 'src/components/BaseModal';
import SelectorMenu from 'src/components/Selector/SelectorMenu';
import { useCategoryTabContext } from '../state';

interface AddCategoryModalProps {
}
const AddCategoryModal: FC<AddCategoryModalProps> = ({ }) => {
  const { state, dispatch, setValue, setUsertype, resetValues, createCategory, updateCategoryValue } = useCategoryTabContext();

  const { value, usertype } = state;

  const handleClose = () => dispatch({ type: 'CLOSE_MODAL' })

  const handleSubmit = async () => {
    if (state.mode === 'CREATE_CATEGORY') {
      createCategory();
    }
    if (state.mode === 'UPDATE_CATEGORY') {
      updateCategoryValue();
    }
  }
  useEffect(() => { console.log(state.value) }, [state.value])
  return (
    <BaseModal open={state.open} handleClose={handleClose}>
      <Typography variant="h2" sx={{
        fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'center'
      }}>
        {state.mode === 'CREATE_CATEGORY' ? 'Add Category' : 'Update Category'}
      </Typography>
      <Box sx={style.innerWrapper}>

        <FormControl>
          <InputLabel variant="standard">
            Category Value
          </InputLabel>
          <Input defaultValue={state.value} sx={{ width: 250 }} onChange={(e) => { setValue(e.currentTarget.value) }} />
        </FormControl>
        {state.mode === 'CREATE_CATEGORY' && <SelectorMenu setValue={setUsertype} value={state.usertype} />}
      </Box>
      <Box sx={style.innerWrapper}>
        <Button onClick={handleSubmit}>{state.mode === 'CREATE_CATEGORY' ? 'ADD CATEGORY' : 'UPDATE CATEGORY'}</Button>
        <Button onClick={handleClose} color="error">CANCEL</Button>
      </Box>
    </BaseModal >
  );
};

const style = {
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: '#fff',
    border: ' 2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 30,
    textAlign: 'center'
  },
  innerWrapper: {
    display: 'flex', justifyContent: 'center', marginTop: '20px'
  }
};
export default AddCategoryModal;
