import { useEffect } from "react";
import { useMainContext } from "src/context";

export default function useLoaderActivityIndicator(value: boolean) {
	const { dispatch } = useMainContext();
	useEffect(() => {
		if (value) {
			dispatch({ type: 'ACTIVATE_LOADER' })
		} else {
			dispatch({ type: 'DEACTIVATE_LOADER' })
		}
	}, [value])
}