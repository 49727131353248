import { ApolloClient, ApolloLink, from, HttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import cache from './cache';
// import awsUser from './cache/awsUser';

const wsLink = new WebSocketLink({
  uri: 'ws://192.34.60.239:8000/graphql',
  // uri: 'ws://192.168.0.10:8000/graphql',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      // awsSub: awsUser()?.attributes.sub,
    }),
  },
});
const httpLink = new HttpLink({
  uri: 'http://192.34.60.239:8000/graphql',
  // uri: 'http://192.168.0.10:8000/graphql',
});
/**
 * Defines the Apollo Client for React
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      // awsloged: awsUser()?.attributes.sub ? true : false,
      // awssub: awsUser()?.attributes.sub,
      recent_activity: new Date(),
    },
  }));
  return forward(operation);
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}`);
      console.log(`[GraphQL error]: Location: ${locations}`);
      console.log(`[GraphQL error]: Path: ${path}`);
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const client = new ApolloClient({
  link: from([authMiddleware, errorLink, splitLink]),
  // uri: 'http://192.168.0.10:8000/graphql',
  cache,
  credentials: 'include',
});

export default client;
