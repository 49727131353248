import React, { createContext, FC, useContext } from "react";
import useRemoveUser from "src/graphql/userQueries/useDeleteFromApi";
import { useVerifyUserMutation, } from "src/graphql/userQueries/useVerifyUser";
import useLoaderActivityIndicator from "src/hook";
import { useUserTabReducer, useUserTabReducerWithMiddleware, initialState, userTabMiddlewares, userTabAfterwares } from "./reducer";
import { UserTabContext } from "./types";

const Context = createContext<UserTabContext>({} as UserTabContext)
export const UserTabContextProvider: FC<{}> = ({ children }) => {
	const [state, dispatch] = useUserTabReducerWithMiddleware(
		useUserTabReducer(),
		initialState,
		userTabMiddlewares,
		userTabAfterwares
	);
	const [verify, verifyTuple] = useVerifyUserMutation({ userId: '' })
	const [remove, removeTuple] = useRemoveUser('');
	useLoaderActivityIndicator(verifyTuple.loading)
	useLoaderActivityIndicator(removeTuple.loading)
	async function verifyUser(input: { userId: string }) {
		await verify({ variables: { input } })

	}
	async function removeUser(input: { userId: string }) {
		await remove({
			variables: {
				id: input.userId
			}
		})
	}
	const value = {
		state, dispatch, verifyUser, removeUser
	}
	return <Context.Provider value={value}>
		{children}
	</Context.Provider>
}

export const useUserTabContext=() => useContext(Context)