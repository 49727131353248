import React, { useEffect, useState, FC } from 'react';
import { useMainContext } from 'src/context';
import { UserTabContextProvider } from './state';
import UserTable from './tables/Userstable';

interface UserTabProps { }

const UserTab: FC<UserTabProps> = () => {
	const mainContext = useMainContext();
	const { tab } = mainContext.state;
	if (tab === 'Users') {

		return (
			<UserTabContextProvider>
				<UserTable />
			</UserTabContextProvider>
		)
	}
	return <></>
}

export default UserTab;