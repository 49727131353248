import { Modal, Box } from '@mui/material';
import React, { useEffect, useState, FC } from 'react';

interface BaseModalProps {
	open: boolean;
	handleClose: () => void;
}
const BaseModal: FC<BaseModalProps> = ({ open, handleClose, children }) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={styles.container}>{children}</Box>
		</Modal>
	);
};

const styles = {
	container: {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 600,
		backgroundColor: '#fff',
		border: ' 2px solid #000',
		boxShadow: 24,
		p: 4,
	},
};

export default BaseModal;