import { InMemoryCache } from '@apollo/client';
// import userProfile from './userProfile';

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    // Query: {
    //   fields: {
    //     awsUser: {
    //       read() {
    //         if (!awsUser()) {
    //           AWS_USER_INITIAL_VALUE();
    //         }
    //         return awsUser();
    //       },
    //     },
    //     userProfile: {
    //       read() {
    //         return userProfile();
    //       },
    //     },
    //   },
    // },
  },
});

export default cache;
