/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, FC, Reducer, Dispatch, useRef } from 'react';
import { CustomReducerMiddleware, NavbarTabs, UserReducerWithMiddlewareType, Usertype } from 'src/types';
import { useImmerReducer } from 'use-immer';

interface MainContextReducerProps { }
export interface IMainState {
  tab: NavbarTabs;
  usertypeSelector: 'coach' | 'athlete' | 'creator' | 'fan' | '';
  loader: boolean;
}
export type IMainActions =
  | { type: 'ACTIVATE_LOADER' }
  | { type: 'DEACTIVATE_LOADER' }
  | { type: 'SET_TAB'; payload: { tab: NavbarTabs } }
  | {
    type: 'SET_USERTYPE_SELECTOR';
    payload: { usertype: Usertype | '' };
  };

export interface MainReducerType extends Reducer<IMainState, IMainActions> { }
export type MainCustomMiddleware = CustomReducerMiddleware<IMainActions, IMainState>;
export type MainUseReducerWithMiddleware = UserReducerWithMiddlewareType<
  IMainState,
  IMainActions,
  MainReducerType,
  MainCustomMiddleware
>;
export interface IMainContext {
  state: IMainState;
  dispatch: Dispatch<IMainActions>;
}

export function useMainReducer() {
  const reducer: MainReducerType = (draft, action) => {
    switch (action.type) {
      case 'SET_TAB':
        draft.tab = action.payload.tab;
        return draft;
      case 'SET_USERTYPE_SELECTOR':
        draft.usertypeSelector = action.payload.usertype;
        return draft;
      case 'ACTIVATE_LOADER':
        draft.loader = true;
        return draft;
      case 'DEACTIVATE_LOADER':
        draft.loader = false;
        return draft;
      default:
        return draft;
    }
  };
  return reducer;
}
export const useMainReducerWithMiddleware: MainUseReducerWithMiddleware = (
  reducer,
  initialState,
  middleware,
  afterware,
) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const ref = useRef({} as IMainActions);
  useEffect(() => {
    if (!ref.current) return;
    afterware.forEach(afterFn => afterFn(ref.current, state, dispatch));
  }, [afterware, state]);
  const customDispatch = (action: IMainActions) => {
    middleware.forEach(midFn => midFn(action, state, dispatch));
    ref.current = action;
    dispatch(action);
  };
  return [state, customDispatch];
};
