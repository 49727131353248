import { gql, useMutation, useQuery } from '@apollo/client';
import { GET_USERS_QUERIES } from './useGetUser';

const REMOVE_USER = gql`
  mutation Query($id: ID) {
    removeUser(id: $id) {
      id
      firstname
      lastname
    }
  }
`;

interface IRemoveUser {
  getUser: null;
}
interface IRemoveUserVar {
  id: string;
}

export default function useRemoveUser(id: string | string) {
  return useMutation<IRemoveUser, IRemoveUserVar>(REMOVE_USER, {
    variables: {
      id: id,
    },
    refetchQueries: [GET_USERS_QUERIES],
    notifyOnNetworkStatusChange: true,
  });
}
