import { gql, useQuery } from '@apollo/client';
import { IUser } from 'src/types';

/**
 * Get Queries
 */

export const GET_USERS_QUERIES = gql`
query GET_ALL_USER{
  getAllUser{
      id
			firstname
			lastname
      fullname
			usertype
			username
			email
      zipcode
			isFilled
			isVerified
  }
}
`;

export function useGetUsers() {
	return useQuery<{ getAllUser: IUser[] }, {}>(GET_USERS_QUERIES, {
		notifyOnNetworkStatusChange: true
	});
}