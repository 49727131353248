/* eslint-disable max-len */
import { ApolloProvider } from '@apollo/client';
import React, { FC, createContext, Dispatch, useContext } from 'react';
import client from '../graphql/client';
import {
  IMainActions,
  IMainState,
  MainCustomMiddleware,
  useMainReducer,
  useMainReducerWithMiddleware,
} from './MainContextReducer';

const initialState: IMainState = {
  tab: 'Categories',
  usertypeSelector: '',
  loader: false,
};
const middlewaresLogger: MainCustomMiddleware = (action, state, dispatch) => {
  console.log(`[Main Reducer] ${action.type} ${state.tab}`);
};
const middlewares: MainCustomMiddleware[] = [middlewaresLogger];
const afterwares: MainCustomMiddleware[] = [];
interface ContextProps { }
interface IMainContext {
  state: IMainState;
  dispatch: Dispatch<IMainActions>;
}
const Context = createContext<IMainContext>({} as IMainContext);
const ContextProvider: FC<ContextProps> = ({ children }) => {
  const [state, dispatch] = useMainReducerWithMiddleware(useMainReducer(), initialState, middlewares, afterwares);
  const value = {
    state,
    dispatch,
  };

  return (
    <ApolloProvider client={client}>
      <Context.Provider value={value}>{children}</Context.Provider>
    </ApolloProvider>
  );
};

export const useMainContext = () => useContext(Context);

export default ContextProvider;
