import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState, FC } from 'react';

interface CustomActivityIndicatorProps {
  mode: 'full' | 'mini'
  open: boolean;
  handleClose: () => void;
}

const CustomActivityIndicator: FC<CustomActivityIndicatorProps> = ({ mode, open, handleClose }) => {
  if (mode === 'full') {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return <></>
};

export default CustomActivityIndicator;
