import { useMutation, gql } from "@apollo/client";
import { VerifyUserInput, VerifyUserResponse } from 'src/types'
import { GET_USERS_QUERIES } from "./useGetUser";

export const VERIFY_USER_MUTATION = gql`
mutation VERIFY_USER($input: VerifyUserInput){
  verifyUser(input:$input){
    error
    message
    user{
      id
      firstname
      lastname
      fullname
      zipcode
      isFilled
      isVerified
    }
  }
}
`;

export function useVerifyUserMutation(input: VerifyUserInput) {
	return useMutation<{ verifyUser: VerifyUserResponse }, { input: VerifyUserInput }>(VERIFY_USER_MUTATION, {
		variables: { input },
		notifyOnNetworkStatusChange: true,
		refetchQueries: [GET_USERS_QUERIES]
	})
}