import React, { FC } from 'react';
import logo from './logo.svg';
import './App.css';
import useRootQuery from './graphql/useRootQuery';
import Navbar from './components/Navbar/Navbar';
import SelectorMenu from './components/Selector/SelectorMenu';
import CategoriesTab from './Tabs/Categories/CategoriesTab';
import CustomActivityIndicator from './components/CustomActivityIndicator/CustomActivityIndicator';
import { useMainContext } from './context';
import UserTab from './Tabs/Users/UserTab';

// import Navbar from '~components/';

const App: FC<{}> = () => {
  const { state, dispatch } = useMainContext();
  const handleClose = () => dispatch({ type: "DEACTIVATE_LOADER" })
  return (
    <div className="App">
      <Navbar />
      <CategoriesTab />
      <UserTab />
      <CustomActivityIndicator open={state.loader} handleClose={handleClose} mode="full" />
    </div>
  );
};

export default App;
