import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { useCreateCategory, useRemoveCategory, useUpdateCategory } from "src/graphql/categoriesQueries";
import useLoaderActivityIndicator from 'src/hook';
import { Usertype } from "src/types";
import { useImmerReducer } from "use-immer";

interface ICreateCategoryState {
  value: string;
  usertype: Usertype | '';
  open: boolean;
  id: string,
  mode: 'CREATE_CATEGORY' | 'UPDATE_CATEGORY'
}
export type CreateCategoryActions =
  | {
    type: 'SET_VALUE', payload:
    | { field: 'value', value: string }
    | { field: 'id', value: string }
    | { field: 'usertype', value: Usertype | '' }
    | { field: 'main', value: boolean }
  }
  | { type: 'RESET_VALUES'; }
  | { type: 'CLOSE_MODAL' }
  | { type: 'OPEN_MODAL' }
  | { type: 'OPEN_CREATE_MODAL' }
  | { type: 'OPEN_UPDATE_MODAL', payload: { value: string; usertype: Usertype; id: string; } }
  ;

const initialState: ICreateCategoryState = {
  value: '',
  usertype: '',
  open: false,
  id: '',
  mode: 'CREATE_CATEGORY',
}

export interface CreateCategoryReducer extends Reducer<ICreateCategoryState, CreateCategoryActions> { }

export function useCreateCategoryReducer() {
  const reducer: CreateCategoryReducer = (draft, action) => {
    switch (action.type) {
      case 'RESET_VALUES':
        draft.usertype = '';
        draft.value = '';
        return draft;
      case 'SET_VALUE':
        if (action.payload.field === 'usertype') {
          draft[action.payload.field] = action.payload.value
        }
        if (action.payload.field === 'value') {
          draft[action.payload.field] = action.payload.value
        }
        if (action.payload.field === 'id') {
          draft[action.payload.field] = action.payload.value
        }
        return draft;
      case 'OPEN_MODAL':
        draft.open = true;
        return draft;
      case 'CLOSE_MODAL':
        draft.open = false;
        return draft;
      case 'OPEN_CREATE_MODAL':
        draft.open = true;
        draft.mode = 'CREATE_CATEGORY';
        draft.value = '';
        draft.usertype = '';
        return draft;
      case 'OPEN_UPDATE_MODAL':
        draft.open = true;
        draft.mode = 'UPDATE_CATEGORY';
        draft.value = action.payload.value;
        draft.usertype = action.payload.usertype;
        draft.id = action.payload.id;
        return draft;
      default:
        return draft;
    }
  }
  return useImmerReducer(reducer, initialState)
}

interface CategoryTabContext {
  state: ICreateCategoryState;
  dispatch: Dispatch<CreateCategoryActions>;
  setUsertype: (value: Usertype | '') => void;
  setValue: (value: string) => void;
  resetValues: () => void;
  createCategory: () => void;
  updateCategoryValue: () => void;
  toggleCategoryMain: () => void;
  removeCategory: () => void;
}
const Context = createContext<CategoryTabContext>({} as CategoryTabContext);

export const CategoryTabContextProvider: FC<{}> = ({ children }) => {
  const [state, dispatch] = useCreateCategoryReducer();
  const { open, value, usertype, id } = state;
  const [create, createTuple] = useCreateCategory({ value, usertype })
  const [update, updateTuple] = useUpdateCategory({ id: '', field: 'main' })
  const [remove, removeTuple] = useRemoveCategory({ id: '' })

  async function createCategory() {
    await create({ variables: { input: { value: state.value, usertype: state.usertype } } })
    dispatch({ type: 'CLOSE_MODAL' })
    resetValues();
  }

  async function updateCategoryValue() {
    await update({ variables: { input: { id, field: 'value', newValue: state.value } } })
    dispatch({ type: 'CLOSE_MODAL' })
    resetValues();
  }

  async function toggleCategoryMain() {
    await update({ variables: { input: { id, field: 'main' } } })
    dispatch({ type: 'CLOSE_MODAL' })
    resetValues();
  }

  async function removeCategory() {
    await remove({ variables: { input: { id } } })
    dispatch({ type: 'CLOSE_MODAL' })
  }


  const setValue = (input: string) => dispatch({ type: 'SET_VALUE', payload: { field: 'value', value: input } });

  const setUsertype = (value: Usertype | '') => dispatch({ type: 'SET_VALUE', payload: { field: 'usertype', value, } });


  const resetValues = () => dispatch({ type: 'RESET_VALUES' })
  const res = { state, dispatch, setValue, resetValues, setUsertype, createCategory, updateCategoryValue, toggleCategoryMain, removeCategory };

  useLoaderActivityIndicator(createTuple.loading);
  useLoaderActivityIndicator(updateTuple.loading);
  useLoaderActivityIndicator(removeTuple.loading);

  return <Context.Provider value={res}>
    {children}
  </Context.Provider>
}

export const useCategoryTabContext = () => useContext(Context);
