/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, FC } from 'react';
import { useMainContext } from 'src/context';
import { useGetCategoriesByUsertype, useRemoveCategory, useUpdateCategory } from 'src/graphql/categoriesQueries';
import { DataGrid, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import { useCategoryTabContext } from './state';

interface CategoriesTableProps { }

const columnsDef = [
  { field: 'value', headerName: 'Value', flex: 1 },
  {
    field: 'main',
    headerName: 'Main',
    minWidth: 220,
    renderCell: (params: GridRenderCellParams) => {
      const { id } = params.row
      const { toggleCategoryMain, updateCategoryValue } = useCategoryTabContext();
      return (
        <Button
          variant="contained"
          onClick={async () => {
            await toggleCategoryMain()
          }}
        >
          <Typography sx={{ color: '#fff', fontSize: 12 }}>{params.value ? 'True' : 'False'}</Typography>
        </Button>
      );
    },
  },
  {
    field: 'delete',
    renderHeader: () => {
      return <></>;
    },
    // headerName: 'Delete',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { id, value, usertype } = params.row;
      const { dispatch } = useCategoryTabContext()
      const { toggleCategoryMain, updateCategoryValue } = useCategoryTabContext();
      return (
        <div style={{ display: 'flex' }}>

          <Button
            variant="contained"
            onClick={() => {
              dispatch({ type: 'SET_VALUE', payload: { field: 'id', value: id } })
              updateCategoryValue()
            }}
          >
            <Typography sx={{ color: '#fff', fontSize: 12 }}>Delete</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => dispatch({ type: 'OPEN_UPDATE_MODAL', payload: { id, value, usertype } })}
            sx={{ marginLeft: '5px' }}
          >
            <Typography sx={{ color: '#fff', fontSize: 12, }}>Update</Typography>
          </Button>
        </div>
      );
    },
  },
];
const CategoriesTable: FC<CategoriesTableProps> = () => {
  const { state, dispatch } = useMainContext();
  const gridRef = useGridApiRef();
  const { data, loading, error, refetch, networkStatus } = useGetCategoriesByUsertype({
    // @ts-ignore
    usertype: state.usertypeSelector,
  });
  useEffect(() => {
  }, [data]);
  useEffect(() => {
  }, [loading])
  if (data?.getCategoriesByUsertype.error === false) {
    return (
      <div style={style.container}>
        <DataGrid disableSelectionOnClick columns={columnsDef} rows={data.getCategoriesByUsertype.categories} />
      </div>
    );
  }
  return <></>;
};

const style = {
  container: {
    height: 400,
    width: '80%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
};
export default CategoriesTable;
