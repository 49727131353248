/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState, FC, forwardRef } from 'react';
import { useMainContext } from 'src/context';
import { NavbarTabs, Usertype, } from 'src/types';

interface SelectorMenuProps {
  value?: Usertype | '';
  setValue: (value: Usertype | '') => void;
}

const SelectorMenu: FC<SelectorMenuProps> = ({ setValue, value }) => {
  const usertypes: Usertype[] = ['fan', 'creator', 'coach', 'athlete'];
  const handleSelect = (e: SelectChangeEvent<Usertype | ''>) => {
    // @ts-ignore
    setValue(e.target.value)
  };
  return (
    <Box>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Usertype</InputLabel>
        <Select
          variant="standard"
          sx={{ m: 1, minWidth: 120 }}
          labelId="demo-simple-select-label"
          onChange={handleSelect}
          value={value}
        >
          {usertypes.map(item => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectorMenu;
