import { useEffect, useRef } from "react";
import { useImmerReducer } from "use-immer";
import { IUserTabState, UserTabActions, UserTabCustomMiddleware, UserTabReducer, UserTabReducerWithMiddleware } from "./types";

export const initialState: IUserTabState = {
	open: false,
}

export function useUserTabReducer() {
	const reducer: UserTabReducer = (draft, action) => {
		switch (action.type) {
			case 'SELECT_USER':
				return draft;
			default:
				return draft;
		}

	}
	return reducer;
}

export const useUserTabReducerWithMiddleware: UserTabReducerWithMiddleware = (reducer, initialState, middlewares, afterwares) => {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const ref = useRef({} as UserTabActions);
	useEffect(() => {
		if (!ref.current) return;
		afterwares.forEach(afterFn => afterFn(ref.current, state, dispatch));
	}, [afterwares, state]);
	const customDispatch = (action: UserTabActions) => {
		middlewares.forEach(midFn => midFn(action, state, dispatch));
		ref.current = action;
		dispatch(action);
	};
	return [state, customDispatch];
}
const middlewaresLogger: UserTabCustomMiddleware = (action, state, dispatch) => {
	console.log(`[ UserTab ] ${action.type}`)
}

export const userTabMiddlewares: UserTabCustomMiddleware[] = [middlewaresLogger]

export const userTabAfterwares: UserTabCustomMiddleware[] = []