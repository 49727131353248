import React, { useEffect, useState, FC } from 'react';
import { useMainContext } from 'src/context';
import Box from '@mui/material/Box';
import SelectorMenu from 'src/components/Selector/SelectorMenu';
import { Button, Typography } from '@mui/material';
import AddCategoryModal from './Modals/AddCategoryModal';
import { Usertype } from 'src/types';
import CategoryTable from './CategoriesTable'
import { CategoryTabContextProvider, useCategoryTabContext } from './state';
interface CategoriesTabProps { }

const CategoriesTab: FC<CategoriesTabProps> = () => {

  const mainContext = useMainContext();
  const { tab } = mainContext.state;

  if (tab === 'Categories') {
    return (
      <>
        <CategoryTabContextProvider>
          <CategoryTabContent />
        </CategoryTabContextProvider>
      </>
    );
  }
  return <></>;
};

const CategoryTabContent: FC<{}> = () => {
  const { dispatch } = useCategoryTabContext();
  const mainContext = useMainContext();
  const { tab } = mainContext.state;
  const handleChange = (e: '' | Usertype) => mainContext.dispatch({ type: 'SET_USERTYPE_SELECTOR', payload: { usertype: e } })
  return (<>
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Typography variant="subtitle1">Select an Usertype</Typography>
        <SelectorMenu setValue={handleChange} />
      </Box>
      <Box>
        <Button onClick={() => dispatch({ type: 'OPEN_CREATE_MODAL' })}>+ Add Category</Button>
      </Box>
    </Box>
    <CategoryTable />
    <AddCategoryModal />
  </>)

}

const styles = {
  container: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    justifyContent: 'space-between',
    paddingRight: 20,
  },
  wrapper: {
    display: 'flex', alignItems: 'center'
  }
}

export default CategoriesTab;
