import React, { useEffect, useState, FC } from 'react';
import Box from '@mui/material/Box';
import { AppBar, Button, IconButton, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { useMainContext } from 'src/context';
import { NavbarTabs } from '../../types.d';

interface NavbarProps { }
export { };
const Navbar: FC<NavbarProps> = () => {
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const { dispatch, state } = useMainContext();

  const handleChange = (event: React.SyntheticEvent, newValue: NavbarTabs) => {
    dispatch({ type: 'SET_TAB', payload: { tab: newValue } });
  };

  return (
    <>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} />
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              align="left"
              sx={{ flexGrow: 1, flexDirection: 'column' }}
            >
              CRTSIDE
            </Typography>
            <Button color="inherit">Login</Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={{ justifyContent: 'space-around', flexGrow: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-around' }}>
          <Tabs value={state.tab} onChange={handleChange} aria-label="Basic tabs example" scrollButtons={true} centered>
            <Tab label="Categories" {...a11yProps(0)} value="Categories" sx={{ flex: 1 }} />
            <Tab label="User" {...a11yProps(0)} value="Users" sx={{ flex: 1 }} />
            <Tab label="Teams" {...a11yProps(0)} value="Teams" sx={{ flex: 1 }} />
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
